define("ember-svg-jar/inlined/chart-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 30H4a2 2 0 01-2-2V2h2v26h26z\"/><path d=\"M10 16a2 2 0 11-2 2 2 2 0 012-2m0-2a4 4 0 104 4 4 4 0 00-4-4zm11-8a4 4 0 11-4 4 4 4 0 014-4m0-2a6 6 0 106 6 6 6 0 00-6-6zm3.5 17a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5m0-2a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
});