define("ember-svg-jar/inlined/chart-scatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30 30H4a2 2 0 01-2-2V2h2v26h26z\"/><circle cx=\"10\" cy=\"22\" r=\"2\"/><circle cx=\"14\" cy=\"15\" r=\"2\"/><circle cx=\"22\" cy=\"15\" r=\"2\"/><circle cx=\"26\" cy=\"6\" r=\"2\"/><circle cx=\"14\" cy=\"8\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
});